import * as React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout/layout"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FormattedTitle from "../components/common/FormattedTitle"
import AnimationCirclePink from "../components/animation/animation-circle-pink"
import AnimationElement1 from "../components/animation/animation-element-1"
import AnimationElement2 from "../components/animation/animation-element-2"
import AnimationCircleWhite from "../components/animation/animation-circle-white"
import { SEO as SearchEngineOptimization } from "../components/common/seo"
import { buildPageDescription } from "../lib/helper"

const ArtistPage = props => {
  const { pageContext } = props
  const {
    title,
    entityLabel,
    body,
    embedCode,
    thumbnail = {},
    artistUrl,
  } = pageContext
  const image = getImage(thumbnail)
  return (
    <Layout>
      <h1>
        <FormattedTitle>{entityLabel}</FormattedTitle>
      </h1>
      <div
        className="content-wrapper container position-relative"
        style={{ zIndex: "2" }}
      >
        {image && (
          <div className="py-4">
            <GatsbyImage
              alt={title}
              image={image}
              imgClassName="preview"
              loading="eager"
              className="rounded-4"
              placeholder="blurred"
            />
          </div>
        )}
        {embedCode?.value && (
          <div
            dangerouslySetInnerHTML={{ __html: embedCode?.value }}
            className="py-4"
          />
        )}
        <div
          dangerouslySetInnerHTML={{ __html: body?.processed }}
          className="node-body py-4 clearfix"
        />
        {artistUrl && (
          <div className="py-2">
            <Link to={artistUrl}>{artistUrl}</Link>
          </div>
        )}
      </div>
      <p className="py-5" style={{ position: "relative", zIndex: 1 }}>
        <Link to="/artists" className="read-more white">
          See all artists >>
        </Link>
      </p>
      <AnimationCircleWhite
        style={{ top: "10%" }}
        className="animation animation-circle-white-1"
      />
      <AnimationCirclePink
        style={{ top: "80%" }}
        className="animation animation-circle-pink-1"
      />
      <AnimationElement1
        className="animation"
        style={{
          position: "absolute",
          top: "30%",
          left: "-150px",
          width: "60px",
        }}
      />
      <AnimationElement2
        className="animation"
        style={{
          top: "50%",
          position: "absolute",
          right: "-150px",
          transform: "scaleX(-1)",
          width: "120px",
        }}
      />
    </Layout>
  )
}

export default ArtistPage

export const Head = ({ pageContext }) => {
  const { title, body, entityUrl, imageUrl } = pageContext
  const description = buildPageDescription(body?.value)
  return (
    <SearchEngineOptimization
      title={title}
      description={description}
      pathname={entityUrl}
      image={imageUrl}
    />
  )
}
